import React from 'react';
import {useIntl, FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl";
import SEO from "../components/seo";
import Layout from '../components/Layout';
import scrollTo from 'gatsby-plugin-smoothscroll';

// Assets

    import ImgQualityOne from '../images/standard/quality-page-1.jpg';
    import ImgQualityTwo from '../images/standard/quality-page-2.jpg';
    import ImgReliabilityOne from '../images/standard/reliability-1.jpg';
    import ImgReliabilityTwo from '../images/standard/reliability-2.jpg';
    import ImgReliabilityThree from '../images/standard/reliability-3.jpg';
    import ImgQualityControl from '../images/standard/quality-control.jpg';
    import ImgDelivery from '../images/standard/delivery.jpg';


const Standard = (props) => {

    const intl = useIntl();

    return (
        <Layout location={props.uri}>
            <div className="page__container-inner">

                <SEO 
                    title={intl.formatMessage({ id: "title.standard" })}
                    description={intl.formatMessage({ id: "description.standard" })}
                    lang={intl.locale}
                />

                <div className="page__sections-container">
                    <div className="page__section page__section--first standard-intro">

                        <article className="split-screen container">

                            <header className="split-screen__item standard-intro__title-container">
                                <h1 className="standard-intro__title"><FormattedHTMLMessage id="standard.intro-main-title" /></h1>
                            </header>

                            <ul className="split-screen__item standard-intro__principles">
                                <li className="standard-intro__principles-item">
                                    <div className="standard-intro__principles-item-link" onClick={() => scrollTo('#quality')}> 
                                        <div className="standard-intro__number">1</div>
                                        <div className="standard-intro__item-title"><FormattedMessage id="standard.intro-quality" /></div>
                                    </div>
                                </li>
                                <li className="standard-intro__principles-item">
                                    <div className="standard-intro__principles-item-link" onClick={() => scrollTo('#reliability')}> 
                                        <div className="standard-intro__number">2</div>
                                        <div className="standard-intro__item-title"><FormattedMessage id="standard.intro-reliability" /></div>
                                    </div>
                                </li>
                                <li className="standard-intro__principles-item">
                                    <div className="standard-intro__principles-item-link" onClick={() => scrollTo('#quality-control')}> 
                                        <div className="standard-intro__number">3</div>
                                        <div className="standard-intro__item-title"><FormattedMessage id="standard.intro-process" /></div>
                                    </div>
                                </li>
                                <li className="standard-intro__principles-item">
                                    <div className="standard-intro__principles-item-link" onClick={() => scrollTo('#delivery')}> 
                                        <div className="standard-intro__number">4</div>
                                        <div className="standard-intro__item-title"><FormattedMessage id="standard.intro-delivery" /></div>
                                    </div>
                                </li>

                            </ul>

                        </article>
                        
                    </div>

                    <div className="page__section quality" id="quality">

                        <article className="split-screen split-screen--uniform-color container">

                            <header className="split-screen__item quality__intro">

                                <h2 className="quality__title"><FormattedMessage id="standard.quality-title" /></h2>

                                <p className="quality__text"><FormattedMessage id="standard.quality-p1" /></p>
                                
                                <p className="quality__text"><FormattedMessage id="standard.quality-p2" /></p>
                                
                                <p className="quality__text"><FormattedMessage id="standard.quality-p3" /></p>

                                <img className="quality__intro-images" src={ImgQualityOne} alt="Publikum standard: Quality" />

                            </header>

                            <div className="split-screen__item quality__other-images">
                                <img src={ImgQualityTwo} alt="Publikum standard: Quality" />
                            </div>

                        </article>

                    </div>

                    <div className="page__section reliability" id="reliability">

                        <article className="split-screen container">

                            <div className="split-screen__item reliability__intro">
                                
                                <h2 className="reliability__title"><FormattedMessage id="standard.reliability-title" /></h2>

                                <p className="reliability__intro-text"><FormattedMessage id="standard.reliability-p1" /></p>

                                <div className="reliability__image-container">
                                    <img className="reliability__image" src={ImgReliabilityOne} alt="Reliability" />
                                    <p className="reliability__caption">FOGRA - ISO 12647</p>
                                </div>

                                

                            </div>

                            <div className="split-screen__item reliability__page-two">

                                <div className="reliability__image-container">
                                    <img className="reliability__image" src={ImgReliabilityTwo} alt="Reliability" />
                                    <p className="reliability__caption">QUALITY AUSTRIA - ISO 9001</p>
                                </div>

                                <div className="reliability__image-container">
                                    <img className="reliability__image" src={ImgReliabilityThree} alt="Reliability" />
                                    <p className="reliability__caption">FSC™ C117682</p>
                                </div>

                            </div>

                        </article>

                    </div>

                    <div className="page__section quality-control" id="quality-control">
                        
                        <article className="quality-control__container">
                            
                            <header className="quality-control__intro">
                                <h2 className="quality-control__title"><FormattedHTMLMessage id="standard.process-title" /></h2>
                                <p className="quality-control__text"><FormattedMessage id="standard.process-p1" /></p>
                            </header>
                            
                            <div className="quality-control__image-container">
                                <img className="quality-control__image" src={ImgQualityControl} alt="Process and quality control" />
                            </div>

                        </article>

                    </div>

                    <div className="page__section delivery" id="delivery">
                        
                        <article className="delivery__container">
                            
                            <header className="delivery__intro">
                                <h2 className="delivery__title"><FormattedMessage id="standard.delivery-title" /></h2>
                                <p className="delivery__text delivery__text--large"><FormattedMessage id="standard.delivery-p1" /></p>
                                <p className="delivery__text"><FormattedMessage id="standard.delivery-p2" /></p>
                            </header>
                            
                            <div className="delivery__image-container">
                                <img className="delivery__image" src={ImgDelivery} alt="Publikum: On time delivery" />
                            </div>

                        </article>
                        
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default Standard;
